<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">

        <div class="col-12">

          <flash message="" flashType=""></flash>

          <div class="card">

            <div class="card-header">
              <h3 class="card-title">List of Payments Report</h3>
            </div>


            <div class="card-header">              
              <div class="card-tools">
                <div class="input-group">

                  <div class="typeDropdown">
                    <v-select label="transTypeName" :options="transTypeOptions" v-model="selectedTransType" @input="loadTransactions" :placeholder="transTypePlaceholder"></v-select>
                  </div>

                  <span class="input-space"></span>
                                            
                  <div class="reportDates">
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                        :minDate="minDate" :maxDate="maxDate"
                        :timePicker="timePicker"
                        :timePickerSeconds="timePickerSeconds"
                        :timePickerIncrement="timePickerIncrement"
                        :showDropdowns="showDropdowns"
                        v-model="dateRange"
                        :ranges="ranges"
                        @update="loadTransactions"
                        :disabled="isLoading"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                      </template>
                    </date-range-picker>
                  </div>
                  
                  <span class="input-space"></span>

                  <div id="user_id_field">
                    <input type="text" class="form-control" v-model="userId" placeholder="Search by User ID" @keyup.enter="loadTransactions" @blur="loadTransactions">
                  </div>

                  <span class="input-space"></span>

                  <template v-if="isDownloading">
                    <div>
                        <spinner :spacing=downloadSpinnerSpacing />
                    </div>
                  </template>
                  <template v-if="!isDownloading">
                    <div class="downloadBtn">
                      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Download
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="download('csv')"
                        >As CSV</a>
                      </div>
                    </div>
                  </template>

                  <span class="input-space"></span>

                  <template>
                    <div>
                      <button class="btn btn-success" @click="downloadCurrentPage"><i class="fas fa-download"></i> Current Page</button>
                    </div>
                  </template>

                  <span class="input-space"></span>

                  <template>
                    <div>
                      <v-select
                          :options="pageOptions"
                          v-model="rowsPerPage"
                          @input="loadTransactions"
                          label="Rows per page"
                          class="page-select"
                      ></v-select>
                    </div>
                  </template>
              
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table id="dataTable" class="table table-hover" aria-label="Deposits and Withdrawals by date range selected">
                <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>User ID</th>
                  <th>Created Date</th>                                    
                  <th>Type</th>                  
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="transactions.data.length > 0 && !isLoading">
                  <tr v-for="transaction in transactions.data" :key="transaction.id">
                    <td>{{ transaction.paymentId | isNotNull }}</td>
                    <td>{{ transaction.userId | isNotNull }}</td>
                    <td>{{ transaction.settlement_date | formatDate | isNotNull }}</td>
                    <td>{{ transaction.type | isNotNull}}</td>
                    <td>{{ transaction.currency | isNotNull }}</td>
                    <td>{{ transaction.amount | isNotNull | formatAmountFilter}}</td>
                    <td>{{ transaction.status | isNotNull }}</td>
                  </tr>
                </template>
                <template v-if="isLoading">
                  <tr>
                    <td colspan="7" style="text-align: center">
                      <spinner :spacing=spinnerSpacing />
                    </td>
                  </tr>
                </template>
                <template v-if="(transactions.data.length === 0 || !transactions.data) && !isLoading">
                  <tr>
                    <td colspan="7" style="text-align: center">No data found within those dates</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <nav aria-label="simple pagination">
                <ul class="pagination justify-content-start mt-4">
                  <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                  </li>
                  <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                    <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from "vue-simple-spinner";

export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(8.5, 'hour').format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().format('YYYY-MM-DD HH:mm:ss');

    return {
      transactions: {
        data: {}
      },
      paginator: {},
      // start date-range-picker params
      opens: 'center',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2024-02-01 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      ranges: this.setRanges(),
      showDropdowns: true,
      // end date-range-picker params
      reportType: 'list-payment',
      //type options
      transTypeOptions: [
        {transTypeName: 'Deposit', value: 'Deposit'},
        {transTypeName: 'Withdraw', value: 'Withdraw'},
        {transTypeName: 'Bonus', value: 'Bonus'},
      ],
      transTypePlaceholder: 'Filter by Type',
      selectedTransType: [],
      userId: null,
      //loading spinner
      isLoading: true,
      spinnerSpacing: 20,

      //downloading spinner
      isDownloading: false,
      downloadSpinnerSpacing: 20,

      //page options
      pageOptions: [20, 50, 100],
      rowsPerPage: 20,
    }
  },
  methods: {
    loadTransactions(){
      this.isLoading = true;
      this.$Progress.start();
      
      axios.get("/api/v1/transactions", {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),          
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : '',
          userId: this.userId ?? null,
          results: this.rowsPerPage ?? 20
        }
      }).then(({ data }) => {
        this.transactions = data;
        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => {
        console.error(error);
        Swal.fire(
            'Unable to load data!',
            "Please try again with a shorter date-time range search. \r\n " +
            "Error: " + error.message,
            'error'
        );
      });
    },
    getResults(url) {
      this.isLoading = true;
      this.$Progress.start();

      axios.get(url, {
        params: {
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : '',
          userId: this.userId ?? null
        }
      }).then(({ data }) => {
        this.transactions = data;
        this.paginator = {
          prev_page_url: data.prev_page_url,
          next_page_url: data.next_page_url
        }
        this.isLoading = false;
        this.$Progress.finish();
      }).catch(error => {
        Swal.fire(
            'Error in loading!',
            error.message + ". Please contact the web administrator" ,
            'error'
        );
        this.$Progress.finish();
        this.isLoading = false;
      })
    },

    download(type) {
      this.$Progress.start();
      this.isDownloading = true;

      flash('Downloading now... Please wait...', 'alert alert-success');

      const filename = 'List_of_Payments_' +
          moment(this.dateRange.startDate).format('YYYY_MM_DD_HH_mm_ss') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY_MM_DD_HH_mm_ss') +
          "." + type;

      axios.get('/api/v1/export/list-payments', {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          type: type,
          reportType: this.reportType,
          transType: this.selectedTransType ? this.selectedTransType.value : '',
          userId: this.userId ?? null,
        },
        responseType: 'blob'
      }).then(response => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a link element to trigger the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.setAttribute('target', '_blank'); // Open in a new tab

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        this.$Progress.finish();
        this.isDownloading = false;
      }).catch(error => {
        Swal.fire(
            'Error on download!',
            error.message + ". Please contact the web administrator" ,
            'error'
        );
        this.$Progress.finish();
        this.isDownloading = false;
      });
    },

    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    },

    downloadCurrentPage() {
      const table = document.getElementById('dataTable');
      const rows = Array.from(table.querySelectorAll('tr'));
      const csvData = [];
      rows.forEach(row => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        const rowData = cells.map(cell => {
          let cellText = cell.innerText;
          if (cellText.includes(',')) {
            cellText = `"${cellText}"`;
          }
          return cellText;
        }).join(',');
        csvData.push(rowData);
      });
      const csvString = csvData.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download',
          'List_Of_Payments_' +
          moment(this.dateRange.startDate).format('YYYY-MM-DD') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY-MM-DD') + '.csv');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {
    this.loadTransactions();    
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatAmountFilter(value) {
      if (value === '' || value === null) {
        value = 0.00
      }
      let val = Number(value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>
<style>
  .input-space {
    padding-left: 35px;
  }

  .card-tools {
    text-align: center; 
    width: 100%;
  }

  .page-select {
    width: 110px;
  }
</style>
