<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" v-if="$gate.isAdmin() || $gate.isBet88()">
                        <div class="card-header">
                            <h3 class="card-title">Registered Players - Admin</h3>
                        </div>
                        
                        <div class="card-header">
                          <div class="input-group w-25 mb-3">
                            <input type="text" class="form-control" placeholder="Filter by User ID" v-model="filter_user_id">
                            <div class="input-group-append">
                              <button class="btn btn-primary" type="button" @click="loadPlayers()">Search</button>
                            </div>
                          </div>

                          <div id="upload-feature">
                            <form @submit.prevent="handleFileUpload" class="form-inline">
                                <div class="form-group col-md-4">
                                    <input class="custom-file-input" type="file" ref="fileInput" accept="text/csv" @change="handleFileChange">
                                    <label class="custom-file-label" :class="{ selected: selected }">{{ fileName }}</label>
                                </div>
                                <div class="pl-2">
                                  <button class="btn btn-primary" type="submit">Upload CSV</button>
                                </div>
                                <div v-if="csvData" class="pl-2">
                                  <button class="btn btn-success" @click="handleImport">Save Players</button>
                                </div>
                            </form>
                          </div>
                        </div>

                        <!-- import table -->
                        <div v-if="csvData" class="card-body table-responsive p-0">
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col" v-for="header in csvData[0]">{{ formatHeader(header) }}</th>
                                  </tr>
                                </thead> 
                                <tbody>
                                  <tr v-for="(row, index) in csvData.slice(1)" :key="index">
                                    <td v-for="cell in row">{{ cell }}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                        <!-- end of import table -->

                        <div class="card-body table-responsive p-0">
                          <!-- registered players table -->
                          <template v-if="registeredPlayers.data.length > 0 && !isLoading">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th>User ID</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(registeredPlayer, index) in registeredPlayers.data" :key="registeredPlayer.id">
                                  <td>{{registeredPlayer.player_id}}</td>
                                  <td :class="{'text-success' : registeredPlayer.status === 'block',
                                          'text-danger': registeredPlayer.status === 'unblock'}">
                                    <span>{{ registeredPlayer.status }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                          <!-- end of registered players table -->

                          <template v-if="isLoading">
                            <table class="table table-hover">
                              <tbody>
                              <tr>
                                <td colspan="2" style="text-align: center">
                                  <spinner :spacing=spinnerSpacing />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </template>

                          <!-- empty table -->
                          <template v-if="(registeredPlayers.data.length === 0 || !registeredPlayers.data) && !isLoading && csvData == null">
                            <table class="table table-hover">
                              <thead>
                              <tr>
                                <th>User ID</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td colspan="2" style="text-align: center">No data was retrieve. Try other date range.</td>
                              </tr>
                              </tbody>
                            </table>
                          </template>
                          <!-- end empty table -->
                        </div>
                        <!-- /card-body -->
                        <div class="card-footer">
                          <nav aria-label="simple pagination">
                            <ul class="pagination justify-content-start mt-4">
                              <li class="page-item" :class="{ disabled: !paginator.prev_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.prev_page_url)" href="#">Previous</a>
                              </li>
                              <li class="page-item" :class="{ disabled: !paginator.next_page_url }">
                                <a class="page-link" @click.prevent="getResults(paginator.next_page_url)" href="#">Next</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                    </div> <!-- /card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Papa from 'papaparse/papaparse.min';
    import Spinner from "vue-simple-spinner";

    export default {
        components: {
          Spinner,
        },
        data() {

            return {
                registeredPlayers: {
                  data: {}
                },

                filter_user_id: "",

                csvData: null,
                fileName: 'Select a CSV file',
                selected: false,
                isUploading: false,

                isLoading: true,
                spinnerSpacing: 20,
                paginator: {},
                per_page: 20,
            };
        },
        methods: {
            handleFileUpload() {
                this.registeredPlayers.data = {};
                this.paginator = {};

                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];
                
                if (file) {
                    this.parseCSV(file);
                } else {
                    Swal.fire(
                        'Error',
                        'Please select a CSV file',
                        'error'
                    );
                }
            },
            handleFileChange(event) {
                const fileInput = event.target;
                this.fileName = fileInput.value.split("\\").pop();
                this.selected = true;
            },
            parseCSV(file) {
                this.isUploading = true;
                const reader = new FileReader();
                
                reader.onload = (event) => {
                    const parsedData = Papa.parse(event.target.result);
                    
                    if (parsedData.data.length > 0) {
                        this.csvData = parsedData.data;
                    } else {
                        alert('The CSV file is empty or has no valid data.');
                    }
                };
                reader.readAsText(file);
                this.isUploading = false;
            },
            formatHeader(header) {
                return header
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            handleImport() {
                this.isUploading = true;
                const playersImport = JSON.stringify(this.convertCsvDataToAssociativeArray(this.csvData));
                
                axios.post('/api/v1/import/registered_players', {
                    'data': playersImport
                })
                .then(
                    Swal.fire(
                        'Imported!',
                        'All players has been saved.',
                        'success'
                    ),
                    this.csvData = null
                )
                .catch(
                    error => {
                        Swal.fire(
                            'Error!',
                             error.message + ". Please check data to import, or contact administrator" ,
                            'error'
                        )
                    }
                )
                .finally(() => {
                  this.isUploading = false;
                });
            },
            convertCsvDataToAssociativeArray() {
                if (!this.csvData || this.csvData.length === 0) {
                    return {};
                }

                const headers = this.csvData[0];
                const data = this.csvData.slice(1);

                return data
                    .filter(row => row.some(cell => cell.trim() !== ""))
                    .map(row => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });
            },

            async loadPlayers() {
                this.csvData = null;
                this.isLoading = true;
                this.$Progress.start();

                const params = { per_page: this.per_page };

                if(this.filter_user_id) {
                  params.filter_user_id = this.filter_user_id;
                }
                
                await axios.get('/api/v1/registered-players', { params }).then(({ data }) => {
                  this.registeredPlayers = data;

                  this.paginator = {
                    prev_page_url: data.prev_page_url,
                    next_page_url: data.next_page_url
                  }

                  this.isLoading = false;
                  this.$Progress.finish();
                }).catch(err => {
                  Swal.fire(
                      'Error loading registered players!',
                      err.message + ". Please try again or ask support.",
                      'error'
                  );
                  this.isLoading = false;
                  this.$Progress.finish();
                })
            },

            getResults(url) {
              this.csvData = null;
              this.isLoading = true;
              this.$Progress.start();

              axios.get(url).then(({ data }) => {
                this.registeredPlayers = data;
                this.paginator = {
                  prev_page_url: data.prev_page_url,
                  next_page_url: data.next_page_url
                }
                this.isLoading = false;
                this.$Progress.finish();
              }).catch(error => {
                Swal.fire(
                    'Error in loading next page!',
                    error.message + ". Please try again or ask support." ,
                    'error'
                );
                this.isLoading = false;
                this.$Progress.finish();
              })
            },
        },

        created() {
          this.loadPlayers();
        }
    };
</script>
<style>
    .custom-file-label::after {
        left: 0;
        right: auto;
        border-left-width: 0;
        border-right: inherit;
    }
</style>
