export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/reports/transactions', component: require('./components/reports/TransactionsReport.vue').default },
    { path: '/reports/list-payments', component: require('./components/reports/ListOfPaymentsReport.vue').default },
    { path: '/reports/revenue-category', component: require('./components/reports/TotalRevenuePerCategoryReport.vue').default },
    { path: '/reports/summary-report-bingo', component: require('./components/reports/SummaryReportBingo.vue').default },
    { path: '/reports/summary-report-slots', component: require('./components/reports/SummaryReportSlots.vue').default },
    { path: '/reports/summary-report-sportsbetting', component: require('./components/reports/SummaryReportSportsbook.vue').default },
    { path: '/reports/game-report-bingo', component: require('./components/reports/GameRevenueBingo.vue').default },
    { path: '/reports/game-report-slots', component: require('./components/reports/GameRevenueSlots.vue').default },
    { path: '/reports/game-report-sportsbook', component: require('./components/reports/GameRevenueSportsbook.vue').default },
    { path: '/reports/branch-report-bingo', component: require('./components/reports/BranchReportBingo.vue').default },
    { path: '/reports/branch-report-slots', component: require('./components/reports/BranchReportSlots.vue').default },
    { path: '/reports/branch-report-sports', component: require('./components/reports/BranchReportSportsbook.vue').default },
    { path: '/reports/registered-players', component: require('./components/reports/RegisteredPlayersReport.vue').default },
    { path: '/reports/games', component: require('./components/reports/GamesReport.vue').default },
    { path: '/settings/games', component: require('./components/settings/Game.vue').default },
    { path: '/settings/whitelistip', component: require('./components/settings/WhitelistIP.vue').default },
    { path: '/maintenance/audit-trail', component: require('./components/maintenance/AuditTrailLog.vue').default },
    { path: '/maintenance/cache', component: require('./components/maintenance/CacheMaintenance.vue').default },
    { path: '/import/transactions', component: require('./components/import/Transactions.vue').default },
    { path: '/import/users', component: require('./components/import/RegisteredPlayers.vue').default },
    { path: '/import/games', component: require('./components/import/GamesImport.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
