<style>
.transDropdown option:first-child {
    text-transform: none !important;
}
</style>
<template>
    <section class="content">
      <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header text-center">
                           <h3 class="card-title mb-0">Audit Trail Logs</h3>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="reportDates">
                                       <date-range-picker
                                          ref="picker"
                                          :opens="opens"
                                          :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:mm:ss' }"
                                          :minDate="minDate" :maxDate="maxDate"
                                          :timePicker="timePicker"
                                          :timePickerSeconds="timePickerSeconds"
                                          :timePickerIncrement="timePickerIncrement"
                                          :showDropdowns="showDropdowns"
                                          v-model="dateRange"
                                          :ranges="ranges"
                                          @update="loadLogs"
                                        >
                                          <template v-slot:input="picker" style="min-width: 350px;">
                                             {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                                          </template>
                                       </date-range-picker>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="transDropdown">
                                        <select class="form-control" v-model="selectedCategory" @change="loadLogs">
                                            <option value="" disabled selected>{{placeholderCategory}}</option>
                                            <option value="all">All</option>
                                            <option
                                                v-for="category in categoryOptions" :key="category"
                                                :value="category">{{ category | capitalize }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="transDropdown">
                                        <select class="form-control" v-model="selectedName" @change="loadLogs">
                                            <option value="" disabled selected>{{placeholder}}</option>
                                            <option value="0">All</option>
                                            <option 
                                                v-for="(name,index) in options" :key="index"
                                                :value="index">{{ name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th class="table-w-15p">Action By</th>
                                    <th class="table-w-15p">Module</th>
                                    <th class="table-w-50p">Details</th>
                                    <th class="table-w-20p">Date Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-if="logs.data.length > 0 && !isLoading">
                                       <tr v-for="log in logs.data" :key="log.id">
                                          <td>{{log.action_by}}</td>
                                          <td class="text-capitalize">{{log.category}}</td>     
                                          <td>{{log.description}}</td>
                                          <td>{{log.created_at}}</td>
                                       </tr>
                                    </template>

                                    <template v-if="isLoading">
                                       <tr>
                                          <td colspan="4">
                                             <spinner />
                                          </td>
                                       </tr>
                                    </template>

                                    <template v-if="logs.data.length === 0 && !isLoading">
                                       <tr>
                                          <td colspan="10" class="text-center">No record found.</td>
                                       </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <template v-if="logs.data.length > 0 && !isLoading">
                           <div class="card-footer">
                              <pagination class="mb-0" :data="logs" @pagination-change-page="getResults"></pagination>
                           </div>
                        </template>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
  
            <div v-if="!$gate.isAdmin()">
               <not-found></not-found>
            </div>
        </div>
    </section>
</template>
  
<script>
    import moment from "moment";
    import Spinner from "vue-simple-spinner";
    
    export default {
        components: {
            Spinner,
        },
        data () {
            let startDate = moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            let endDate = moment().format('YYYY-MM-DD HH:mm:ss');

            return {
                logs : {
                  data: []
                },
                opens: 'right',
                dateRange: {
                    startDate,
                    endDate
                },
                minDate: moment('2022-01-01 HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                timePicker: true,
                timePickerSeconds: true,
                timePickerIncrement: 1,
                showDropdowns: true,
                ranges: this.setRanges(),
                options: [],
                categoryOptions: [],
                selectedName: '',
                selectedCategory: '',
                placeholder: "Filter by Name",
                placeholderCategory: "Filter by Module",
                isLoading: true
            }
        },
        methods: {
            getResults(page = 1) {
                this.$Progress.start();
                this.isLoading = true;

                const params = {
                    startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
                }

                if (this.selectedName && this.selectedName !== "0") {
                    params.user_id = this.selectedName;
                }

                if (this.selectedCategory && this.selectedCategory !== "all") {
                    params.category = this.selectedCategory;
                }
                
                axios.get('/api/audit-trail?page=' + page, { params })
                    .then(({data}) => this.logs = data.data)
                    .finally(() => {
                        this.isLoading = false;
                        this.$Progress.finish();
                    });
                
            },

            async loadLogs(){
               this.$Progress.start();
                this.isLoading = true;
                const params = {
                    startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
                }

                if (this.selectedName && this.selectedName !== "0") {
                    params.user_id = this.selectedName;
                }

                if (this.selectedCategory && this.selectedCategory !== "all") {
                    params.category = this.selectedCategory;
                }

                await axios.get("/api/audit-trail", { params })
                     .then(({data}) => this.logs = data.data)
                     .finally(() => {
                        this.isLoading = false;
                        this.$Progress.finish();
                     });
            },

            async getNames() {
                await axios.get("/api/audit-trail/names")
                    .then(({ data }) => this.options = data.data)
                    .catch(error => console.error(error));
            },

            async getExistingCategories() {
                await axios.get("/api/audit-trail/categories")
                    .then(({ data }) => this.categoryOptions = data.data)
                    .catch(error => console.error(error));
            },

            setRanges() {
                let today = new Date()
                today.setHours(0, 0, 0, 0)
                let todayEnd = new Date()
                todayEnd.setHours(23, 59, 59, 999);

                let yesterdayStart = new Date()
                yesterdayStart.setDate(today.getDate() - 1)
                yesterdayStart.setHours(0, 0, 0, 0);

                let yesterdayEnd = new Date()
                yesterdayEnd.setDate(today.getDate() - 1)
                yesterdayEnd.setHours(23, 59, 59, 999);

                let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
                let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

                return {
                  'Today': [today, todayEnd],
                  'Yesterday': [yesterdayStart, yesterdayEnd],
                  'This month': [thisMonthStart, thisMonthEnd],
                  'This year': [
                  new Date(today.getFullYear(), 0, 1),
                  new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
                  ],
                  'Last month': [
                  new Date(today.getFullYear(), today.getMonth() - 1, 1),
                  new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
                  ],
                }
            }
        },
        created() {
            this.loadLogs();
            this.getNames();
            this.getExistingCategories();
        },
        filters: {
            formatDateRangeInput(value) {
               return moment(value).format('YYYY-MM-DD HH:mm:ss');
            },
            capitalize(value) {
               return value[0].toUpperCase() + value.slice(1);
            }
        }
    }
</script>
