<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="$gate.isAdmin() || $gate.isBet88() || $gate.isBingo()">
            <div class="card-header">
              <h3 class="card-title">Summary Report (Bingo) - GGR</h3>
            </div>

            <div class="card-header">
              <div class="card-tools">
                <div class="reportDates input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                      </svg>
                    </div>
                  </div>
                  <date-range-picker
                      ref="picker"
                      :opens="opens"
                      :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                      :minDate="minDate" :maxDate="maxDate"
                      :timePicker="false"
                      :showDropdowns="showDropdowns"
                      v-model="dateRange"
                      :ranges="ranges"
                      :disabled="isLoading"
                      @update="loadSummary"
                  >
                    <template v-slot:input="picker" style="min-width: 350px;">
                      {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                    </template>
                  </date-range-picker>

                  <div class="pl-4">
                    <button class="btn btn-primary" @click="downloadCSV">Download</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table id="dataTable" class="table table-hover">
                <thead class="blue">
                <tr>
                  <th>Type</th>
                  <th>Total Amount</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!isLoading && !isNone">
                  <tr>
                    <td>Standard Bets</td>
                    <template>
                      <td v-if="isBetsLoading">
                        <spinner :spacing=spinnerSpacing />
                      </td>
                      <td v-if="!isBetsLoading">{{ totalStandardBets | isNotNull | formatAmountFilter}}</td>
                    </template>
                  </tr>
                  <tr>
                    <td>Standard Wins</td>
                    <template>
                      <td v-if="isWinsLoading">
                        <spinner :spacing=spinnerSpacing />
                      </td>
                      <td v-if="!isWinsLoading">{{ totalStandardWins | isNotNull |  formatAmountFilter }}</td>
                    </template>
                  </tr>
                  <tr class="bg-warning">
                    <td>GGR</td>
		    <td>{{ totalRevenue | isNotNull |  formatAmountFilter }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Headcount</td>
                    <template>
                      <td v-if="isHeadCountLoading">
                        <spinner :spacing=spinnerSpacing />
                      </td>
                      <td v-if="!isHeadCountLoading">{{ totalHeadCount | isNotNull | formatWholeNumber }}</td>
                    </template>
                  </tr>
                </template>
                <template v-if="isLoading">
                  <tr>
                    <td colspan="2" style="text-align: center">
                      <spinner :spacing=spinnerSpacing />
                    </td>
                  </tr>
                </template>
                <template v-if="!isLoading && isNone">
                  <tr>
                    <td colspan="2" style="text-align: center">No data was retrieve. Try other date range.</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->

          <div v-else>
            <not-found></not-found>
          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from "vue-simple-spinner";

export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD HH:mm:ss')
    let endDate = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD HH:mm:ss')

    return {
      totalStandardBets : 0,
      totalStandardWins : 0,
      totalHeadCount : 0,
      totalRevenue: 0,
      //date-range-picker params
      opens: 'right',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2023-01-15 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timePicker: true,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params
      isLoading: true,
      spinnerSpacing: 20,
      isNone: false,
      isBetsLoading: true,
      isWinsLoading: true,
      isHeadCountLoading: true,
    }
  },
  methods: {
    async fetchDataSummary() {
      let res = await axios.get('/api/v1/summary/category_report_entries', {
        params: {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
          category: 'BINGO'
        }
      })
      res = res.data
      for(let i in res) {
        this.totalStandardBets = parseFloat(this.totalStandardBets) + parseFloat(res[i].total_standard_bets)
        this.totalStandardWins = parseFloat(this.totalStandardWins) + parseFloat(res[i].total_standard_wins)
	      this.totalRevenue = parseFloat(this.totalRevenue) + parseFloat(res[i].total_revenue)
        if(res[i].total_head_counts) this.totalHeadCount =  this.totalHeadCount + res[i].total_head_counts
      }

      if(this.totalHeadCount === 0 || !this.totalHeadCount) {
        this.getHeadCount()
      }

      if(res.length > 0) {
        this.isLoading = false;
        return res[0]
      }
      else return false
    },
    fetchData(endpoint, property) {
      const params = new URLSearchParams({
        category: "BINGO",
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
      });

      axios.get(`/api/v1/summary/${endpoint}?${params.toString()}`, {
        responseType: 'arraybuffer'
      }).then(response => {
        if (response.data && response.data.byteLength === 0) {
          console.log(`There is no data from response stream for ${property}...`);
          this.isLoading = false;
        }

        const data = new Uint8Array(response.data);
        let total = 0;
        // Convert Uint8Array data to a string
        const stringValue = new TextDecoder().decode(data);
        // Split the string by newline character to separate individual values
        const values = stringValue.split('\n\n');
        // Iterate over each value and parse it as a float
        values.forEach(value => {
          const floatValue = parseFloat(value.replace("data: ", ""));
          if (!isNaN(floatValue)) {
            // Add the parsed float value to the total
            total += floatValue;
          }
        });
        // Update the property with the total
        this[property] += total;
      }).catch(this.handleError);
    },
    async fetchHeadCountData(property) {
      const params = new URLSearchParams({
        category: "BINGO",
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
      });

      await axios.get(`/api/v1/summary/players?${params.toString()}`, {
        responseType: 'arraybuffer'
      }).then(response => {
        if (response.data && response.data.byteLength === 0) {
          console.log(`There is no data from response stream for ${property}...`);
          this.isLoading = false;
          this.isNone = true;
        }

        const data = new Uint8Array(response.data);
        // Convert Uint8Array data to a string
        const stringValue = new TextDecoder().decode(data);
        // Split the string by newline character to separate individual values
        const values = stringValue.split('\n\n');

        let allUserIds = [];

        values.forEach(value => {
          const userId = parseInt(value.replace("data: ", ""));

          if (!isNaN(userId)) {
            allUserIds.push(userId)
          }
        });

        let uniqueUserIds = [...new Set(allUserIds)];

        // Update the property with the count of unique ids
        this[property] += uniqueUserIds.length;
      }).catch(this.handleError);
    },
    handleError(error) {
      Swal.fire(
          'Error loading data!',
          error.message + ". Please contact the web administrator",
          'error'
      );
      this.$Progress.finish();
      this.isLoading = false;
    },
    getBets() {
      this.fetchData('bets', 'totalStandardBets');
    },
    getWins() {
      this.fetchData('wins', 'totalStandardWins');
    },
    getHeadCount() {
      this.fetchHeadCountData('totalHeadCount');
    },

    async loadSummary(){
      try {
        //reset totals to 0
        this.totalStandardBets = 0;
        this.totalStandardWins = 0;
        this.totalHeadCount = 0;
	      this.totalRevenue = 0;

        this.isLoading = true;
        this.$Progress.start();

        let loadSummary = await this.fetchDataSummary()
        if(loadSummary) this.isNone = false;
        else this.isNone = true;
      } catch (err) {
        Swal.fire(
            'Error loading revenues!',
            err.message + ". Please try again later.",
            'error'
        );
        this.isLoading = false;
        this.$Progress.finish();
      }
    },

    downloadCSV() {
      const table = document.getElementById('dataTable');
      const rows = Array.from(table.querySelectorAll('tr'));
      const csvData = [];

      rows.forEach(row => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        const rowData = cells.map(cell => {
          let cellText = cell.innerText;
          if (cellText.includes(',')) {
            cellText = `"${cellText}"`;
          }
          return cellText;
        }).join(',');
        csvData.push(rowData);
      });

      const csvString = csvData.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download',
          'Summary_Report_Bingo_' +
          moment(this.dateRange.startDate).format('YYYY-MM-DD_HH_mm_ss') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY-MM-DD_HH_mm_ss') + '.csv');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    }

  },
  computed: {
    computedGrandTotal() {
     return this.totalRevenue !== 0 ? this.totalRevenue : this.totalStandardBets + this.totalStandardWins;
    }
  },
  created() {
    this.loadSummary();
  },
  watch: {
    computedGrandTotal(newValue) {
      this.isNone = newValue === 0;
      this.isLoading = !(newValue !== 0);
    },
    totalStandardBets(newValue) {
      this.isBetsLoading = newValue === 0;
    },
    totalStandardWins(newValue) {
      this.isWinsLoading = newValue === 0;
    },
    totalHeadCount(newValue) {
      this.isHeadCountLoading = newValue === 0;
    }
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formatAmountFilter(value) {
      if (value === '' || value === null) {
        value = 0.00
      }
      value = parseFloat(value)
      let val;
      val = Number(value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatWholeNumber(value) {
      let val = (value).toFixed()
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}

</script>
